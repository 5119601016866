.select-wrapper {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.signup-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-logo-container {
  display: flex;
  flex-direction: row-reverse;
}
