.lato {
    font-family: "Lato", sans-serif !important;
    font-weight: 300 !important;
}

.lato-regular {
    font-family: "Lato", sans-serif !important;
    font-weight: normal !important;
}

.lato-bold {
    font-family: "Lato", sans-serif !important;
    font-weight: 700 !important;
}

.lato-extra-bold {
    font-family: "Lato", sans-serif !important;
    font-weight: 900 !important;
}

@mixin lato {
    font-family: "Lato", sans-serif !important;
    font-weight: 300 !important;
}

@mixin lato-regular {
    font-family: "Lato", sans-serif !important;
    font-weight: normal !important;
}

@mixin lato-bold {
    font-family: "Lato", sans-serif !important;
    font-weight: 700 !important;
}

@mixin lato-extra-bold {
    font-family: "Lato", sans-serif !important;
    font-weight: 900 !important;
}

//Poppins

@mixin poppins {
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
}

@mixin poppins-regular {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
}

@mixin poppins-semi-bold {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
}

@mixin poppins-bold {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
}

.poppins-regular {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
}

.poppins-semi-bold {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
}

.poppins-bold {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
}

//, sans-serif
.poppins-extra-bold {
    font-family: "Poppins", sans-serif !important;
    font-weight: 800 !important;
}

.poppins-extra-bolder {
    font-family: "Poppins", sans-serif !important;
    font-weight: bold !important;
}

// Myriad
@font-face {
    font-family: "MyriadPro";
    src: local("MyriadPro-Regular"),
        url("../fonts/MYRIADPRO-REGULAR.OTF") format("otf");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: MyriadPro;
    src: local(MyriadPro-Semibold),
        url("../fonts/MYRIADPRO-SEMIBOLD.OTF") format("otf");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

.myriadpro-slim {
    font-family: "MyriadPro", sans-serif !important;
    font-weight: 100 !important;
}

.myriadpro-regular {
    font-family: "MyriadPro", sans-serif !important;
    font-weight: 400 !important;
}

.myriadpro-semi-bold {
    font-family: "MyriadPro", sans-serif !important;
    font-weight: 600 !important;
}

@mixin myriadpro-regular {
    font-family: "MyriadPro", sans-serif !important;
    font-weight: 400 !important;
}

@mixin opensans-regular {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400 !important;
}

@mixin opensans-semi-bold {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
}

.opensans-semi-bold {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
}

@mixin opensans-bold {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700 !important;
}

/* Font Size */
.f7 {
    font-size: 7px !important;
}
.f8 {
    font-size: 8px !important;
}
.f9 {
    font-size: 9px !important;
}
.f10 {
    font-size: 10px !important;
}

.f11 {
    font-size: 11px !important;
}

.f12 {
    font-size: 12px !important;
}

.f13 {
    font-size: 13px !important;
}

.f14 {
    font-size: 14px !important;
}

.f15 {
    font-size: 15px !important;
}

.f16 {
    font-size: 16px !important;
}

.f17 {
    font-size: 17px !important;
}

.f18 {
    font-size: 18px !important;
}

.f19 {
    font-size: 19px !important;
}

.f20 {
    font-size: 20px !important;
}

.f21 {
    font-size: 21px !important;
}

.f22 {
    font-size: 22px !important;
}

.f23 {
    font-size: 23px !important;
}

.f24 {
    font-size: 24px !important;
}

.f25 {
    font-size: 25px !important;
}

.f26 {
    font-size: 26px !important;
}

.f27 {
    font-size: 27px !important;
}

.f28 {
    font-size: 28px !important;
}

.f29 {
    font-size: 29px !important;
}

.f30 {
    font-size: 30px !important;
}

.f31 {
    font-size: 31px !important;
}

.f32 {
    font-size: 32px !important;
}

.f33 {
    font-size: 33px !important;
}

.f34 {
    font-size: 34px !important;
}

.f35 {
    font-size: 35px !important;
}

.f36 {
    font-size: 36px !important;
}

.f37 {
    font-size: 37px !important;
}

.f38 {
    font-size: 38px !important;
}

.f39 {
    font-size: 39px !important;
}

.f40 {
    font-size: 40px !important;
}

.f41 {
    font-size: 41px !important;
}

.f42 {
    font-size: 42px !important;
}

.f43 {
    font-size: 43px !important;
}

.f44 {
    font-size: 44px !important;
}

.f45 {
    font-size: 45px !important;
}

.f46 {
    font-size: 46px !important;
}

.f47 {
    font-size: 47px !important;
}

.f48 {
    font-size: 48px !important;
}

.f49 {
    font-size: 49px !important;
}

.f50 {
    font-size: 50px !important;
}

.f150 {
    font-size: 150px !important;
}

.f180 {
    font-size: 180px !important;
}

.h-40 {
    height: 40px !important;
}

// border-radius
.br-6 {
    border-radius: 6px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-12 {
    border-radius: 12px !important;
}

// Border
.b-light-blue-grey {
    border: 1px solid $light-blue-grey !important;
}

// Font-weight
.font-weight-100 {
    font-weight: lighter !important;
}
