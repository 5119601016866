.min-vh-100 {
    min-height: 100vh !important;
}

.vh-100 {
    height: 100vh !important;
}

.MuiOutlinedInput-input {
    padding: 14px 14px !important;
}

.btn-image {
    float: right !important;
}
