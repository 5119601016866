.welcome-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    // background-color: yellow;
    // border-radius: 16px !important;
    // height: 200px;
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 200px;
    padding: 20px;
    background-color: yellow;
    border-radius: 16px;
}
