.home-container {
    height: 100vh;
    display: flex;
    .right-panel {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
        flex: 1;
        display: flex;
        flex-direction: column;
        .page-header,
        .hamburger-page-header {
            position: sticky;
            top: 0;
        }
    }
}
