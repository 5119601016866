@import "./variables";

// colors
.white {
    color: $white !important;
}
.black {
    color: $black !important;
}

.main-primary {
    color: $main-primary !important;
}
.text-main {
    color: $text-main !important;
}
.main-secondary {
    color: $main-secondary !important;
}
.text-greyed {
    color: $text-greyed !important;
}
.main-neutral {
    color: $main-neutral !important;
}
.background-black {
    color: $background-black !important;
}
.background-green {
    color: $background-green !important;
}
.primary-scale-600 {
    color: $primary-scale-600 !important;
}
.primary-scale-500 {
    color: $primary-scale-500 !important;
}
.primary-scale-200 {
    color: $primary-scale-200 !important;
}
.secondary-scale-200 {
    color: $secondary-scale-200 !important;
}
.secondary-scale-300 {
    color: $secondary-scale-300 !important;
}
.secondary-scale-500 {
    color: $secondary-scale-500 !important;
}
.primary-scale-300 {
    color: $primary-scale-300 !important;
}
.secondary-scale-600 {
    color: $secondary-scale-600 !important;
}
.neutral-scale-200 {
    color: $neutral-scale-200 !important;
}
.interface-opposite {
    color: $interface-opposite !important;
}
.text-secondary {
    color: $text-secondary !important;
}
.text-primary {
    color: $text-primary !important;
}
.neutral-scale-300 {
    color: $neutral-scale-300 !important;
}
.interface-tone-2 {
    color: $interface-tone-2 !important;
}
.interface-tone-1 {
    color: $interface-tone-1 !important;
}
.interface-dark {
    color: $interface-dark !important;
}
.piss-yellow {
    color: $piss-yellow !important;
}
.system-blue-dark {
    color: $system-blue-dark !important;
}
.colour-palette-tertiary {
    color: $colour-palette-tertiary !important;
}
.ruby {
    color: $ruby !important;
}
.system-blue-light {
    color: $system-blue-light !important;
}
.system-teal-dark {
    color: $system-teal-dark !important;
}
.colours-error-background {
    color: $colours-error-background !important;
}

.very-light-pink {
    color: $very-light-pink !important;
}
.secondary-grey {
    color: $secondary-grey !important;
}
.primary-yellow {
    color: $primary-yellow !important;
}

// ANZ
.ocean-blue {
    color: $ocean-blue !important;
}
.warm-grey {
    color: $warm-grey !important;
}
.ocean-blue-two {
    color: $ocean-blue-two !important;
}
.prussian-blue {
    color: $prussian-blue !important;
}
.primary-green {
    color: $primary-green !important;
}
.primary-red {
    color: $primary-red !important;
}
.pale-yellow {
    color: $pale-yellow !important;
}

// background colors
.bg-white {
    background-color: $white !important;
}
.bg-black {
    background-color: $black !important;
}
.bg-main-primary {
    background-color: $main-primary !important;
}
.bg-text-main {
    background-color: $text-main !important;
}
.bg-main-secondary {
    background-color: $main-secondary !important;
}
.bg-text-greyed {
    background-color: $text-greyed !important;
}
.bg-main-neutral {
    background-color: $main-neutral !important;
}
.bg-background-black {
    background-color: $background-black !important;
}
.bg-background-green {
    background-color: $background-green !important;
}
.bg-primary-scale-600 {
    background-color: $primary-scale-600 !important;
}
.bg-primary-scale-500 {
    background-color: $primary-scale-500 !important;
}
.bg-primary-scale-200 {
    background-color: $primary-scale-200 !important;
}
.bg-secondary-scale-200 {
    background-color: $secondary-scale-200 !important;
}
.bg-secondary-scale-300 {
    background-color: $secondary-scale-300 !important;
}
.bg-secondary-scale-500 {
    background-color: $secondary-scale-500 !important;
}
.bg-primary-scale-300 {
    background-color: $primary-scale-300 !important;
}
.bg-secondary-scale-600 {
    background-color: $secondary-scale-600 !important;
}
.bg-neutral-scale-200 {
    background-color: $neutral-scale-200 !important;
}
.bg-interface-opposite {
    background-color: $interface-opposite !important;
}
.bg-text-secondary {
    background-color: $text-secondary !important;
}
.bg-text-primary {
    background-color: $text-primary !important;
}
.bg-neutral-scale-300 {
    background-color: $neutral-scale-300 !important;
}
.bg-interface-tone-2 {
    background-color: $interface-tone-2 !important;
}
.bg-interface-tone-1 {
    background-color: $interface-tone-1 !important;
}
.bg-interface-dark {
    background-color: $interface-dark !important;
}
.bg-piss-yellow {
    background-color: $piss-yellow !important;
}
.bg-system-blue-dark {
    background-color: $system-blue-dark !important;
}
.bg-colour-palette-tertiary {
    background-color: $colour-palette-tertiary !important;
}
.bg-ruby {
    background-color: $ruby !important;
}
.bg-system-blue-light {
    background-color: $system-blue-light !important;
}
.bg-system-teal-dark {
    background-color: $system-teal-dark !important;
}
.bg-colours-error-background {
    background-color: $colours-error-background !important;
}
.bg-very-light-pink {
    background-color: $very-light-pink !important;
}
.bg-secondary-grey {
    background-color: $secondary-grey !important;
}
.bg-primary-yellow {
    background-color: $primary-yellow !important;
}

// ANZ
.bg-whiteThree {
    background-color: $whiteThree !important;
}
.bg-pale-blue {
    background-color: $pale-blue !important;
}

.bg-pale-red {
    background-color: $pale-red !important;
}

.bg-ocean-blue-two {
    background-color: $ocean-blue-two !important;
}

.bg-light-blue-grey {
    background-color: $light-blue-grey !important;
}

.bg-white-four {
    background-color: $white-four !important;
}

.bg-pale-red-two {
    background-color: $pale-red-two !important;
}

.bg-pale-grey-two {
    background-color: $pale-grey-two !important;
}

.bg-pale-yellow {
    background-color: $pale-yellow !important;
}

.bg-header-gradient {
    background: linear-gradient(
        -180deg,
        rgb(0, 65, 101) 0%,
        rgb(0, 128, 189) 100%
    ) !important;
    height: 120px;
}

// Border
.b-ocean-blue-two {
    border: 1px solid $ocean-blue-two !important;
}

.b-white-five {
    border: 1px solid $white-five !important;
}
