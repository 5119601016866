@import "./colors";

.app-color-input {
    background-color: $primary-light;
    border: 1px solid $primary !important;
    border-radius: 5px;
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
