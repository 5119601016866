@import "../colors";
@import "../fonts";

.side-menu {
    width: 300px;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-right: 2px solid $primary-light;
    .logo-img {
        margin: 20px 40px;
        width: 200px;
    }
    .login-info-container {
        margin: 20px;
        border: 2px solid $primary-light;
        border-radius: 10px;
        .user-menu {
            @include lato-regular;
            font-weight: 300;
            padding: 10px 20px;
            border-bottom: 2px solid $primary-light;
            &:hover {
                background-color: $primary-light;
                cursor: pointer;
            }
        }
        .red {
            color: #e22727;
        }
        .login-user {
            @include poppins-regular;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $primary-light;
            img {
                width: 25px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .small-font {
            font-weight: 300;
            font-size: 14px;
        }
        .user-name {
            color: $primary;
            margin-top: 5px;
            font-weight: 500;
        }
    }
    .menus {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .menu {
            color: $secondary-grey;
            @include lato-regular;
            font-size: 16px;
            height: 40px;
            display: flex;
            align-items: center;
            padding-left: 40px;
            margin-bottom: 20px;
            text-decoration: none;
            img {
                width: 30px;
                margin-right: 20px;
            }
        }
        .active-menu {
            color: $primary;
            border-right: 5px solid $primary;
        }
    }
}
