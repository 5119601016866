$main-primary: #36bb79;
$text-main: #0a0d15;
$main-secondary: #1c3a6c;
$text-greyed: #3b4250;
$main-neutral: #151a26;
$background-black: #e9ecf1;
$background-blue: #e8ebf0;
$background-green: #eaf8f1;
$primary-scale-600: #076034;
$primary-scale-500: #179456;
$primary-scale-200: #9addbc;
$secondary-scale-200: #8d9cb5;
$secondary-scale-300: #496189;
$secondary-scale-500: #112c5a;
$primary-scale-300: #5ec994;
$secondary-scale-600: #07204b;
$neutral-scale-200: #d0d4db;
$interface-opposite: #0f264c;
$text-secondary: #4a5d83;
$text-primary: #0f264c;
$neutral-scale-300: #6a707f;
$interface-tone-2: #ecf1fb;
$interface-tone-1: #f8faff;
$interface-dark: #99acd0;
$piss-yellow: #daaa21;
$system-blue-dark: #0084ff;
$colour-palette-tertiary: #ea7c79;
$ruby: #e02020;
$system-blue-light: #0a7aff;
$ruby: #e02020;
$system-teal-dark: #64d2ff;
$colours-error-background: #600707;
$white: #ffffff;
$black: #000000;
$very-light-pink: #fdf9f3;
$secondary-grey: #5e5d5d;
$primary-yellow: #d88c22;
$primary-light: #fbf3e8;
$primary: #d88c22;
$text-black: #1b1a1a;
$btn-danger: #e22727;

// ANZ
$whiteThree: #f7f7f7;
$ocean-blue: #0072ae;
$pale-blue: #e5f0f6;
$marine-blue: #004165;
$ocean-blue-two: #0072ac;
$pale-red: #dc3838;
$warm-grey: #979797;
$white-five: #e0e0e0;
$prussian-blue: #024a70;
$light-blue-grey: #dae7ed;
$white-four: #e6e6e6;
$pale-red-two: #d73b33;
$pale-grey-two: #ecf4fa;
$pale-yellow: #daaa21;
$primary-green: #008a02;
$primary-dark-green: #007401;
$primary-light-green: #ebf6eb;
$primary-yellow: #df7a00;
$primary-light-yellow: #fbefe0;
$primary-red: #d73b33;
$primary-light-red: #fae7e6;
$pale-light-blue-100: #ebf4f9;
$pale-light-blue-200: #cae1ec;
$dark-blue: #007dba;
$grey-500: #747678;
