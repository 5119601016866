.user-report-table {
    width: 100% !important;
    padding: 20px 20px 0px 20px !important;
}

.overflow-scroll {
    // overflow-x: scroll !important;
}

.text-word-break {
    word-break: break-word !important;
    width: 13%;
    // max-width: 100%;
}
.group-box {
    display: flex;
    align-items: center;
    .group-selection {
        max-width: 250px;
        min-width: 250px;
    }
}
#menu- {
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: #e1f0f8 !important;
    }
    .select-all {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
    }
}
