@import "./login";
@import "./signup";
@import "./email-confirmation";

.auth-layout {
    height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    .left-panel {
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        padding: 50px;
    }
    .right-panel {
        display: flex;
        flex-direction: column;
        padding: 50px;
        justify-content: space-between;
        min-height: 100%;
        .center-image {
            margin: 20px 0px;
            text-align: center;
        }
    }

    .intro-image {
        width: 300px;
    }
}
