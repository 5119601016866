@import "./colors";
@import "./fonts";

.MuiButton-startIcon {
    position: absolute;
    margin: 0px !important;
}

.MuiButton-containedSizeLarge {
    padding: 10px 22px !important;
}

.btn-1 {
    padding: 12px 16px 12px 16px !important;
    height: 48px !important;
    width: 100%;
    @extend .f12;
    box-sizing: border-box;
    @extend .lato-regular;
    border-radius: 5px !important;
    text-transform: none !important;
}

@mixin btn {
    @extend .f10;
    box-sizing: border-box;
    @extend .myriadpro-semi-bold;
    border-radius: 4px !important;
    font-weight: 800;
    box-shadow: none;
    padding: 2px 6px 4px 6px;
    text-transform: uppercase !important;
}

button {
    text-transform: none !important;
}

.btn {
    @include btn;
}

.btn-grey {
    background-color: #f1f1f1 !important;
    color: black;
    @include btn;
}

.btn-primary {
    background-color: $primary !important;
    color: white !important;
    @include btn;
}

.btn-danger {
    background-color: $primary-light-red !important;
    color: $primary-red !important;
    @include btn;
}

.btn-success {
    background-color: $primary-light-green !important;
    color: $primary-green !important;
    @include btn;
}

.btn-inprogress {
    background-color: $primary-light-yellow !important;
    color: $primary-yellow !important;
    @include btn;
}

.btn-outline-succes {
    background-color: rgba(54, 187, 121, 0.05) !important;
    border: 1px solid $main-primary !important;
    color: $main-primary !important;
    @include btn;
}

.btn-round {
    border-radius: 50px !important;
}

.btn-loader {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
}

.btn-blue {
    background-color: $ocean-blue-two !important;
    color: $white !important;
    @include btn;
}
.btn-downloaded {
    background-color: $pale-light-blue-200 !important;
    color: $ocean-blue-two !important;
    @include btn;
}

.btn-yellow {
    background-color: $pale-yellow !important;
    color: $white !important;
    @include btn;
}

.btn-pale-green {
    background-color: $primary-scale-200 !important;
    color: $white !important;
    @include btn;
}

.btn-review {
    @extend .b-ocean-blue-two;
    color: $ocean-blue-two !important;
    @include btn;
}

.btn-download {
    background-color: rgb(0, 125, 186) !important;
    color: $white !important;
    border-radius: 4px !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500 !important;
    &:hover {
        background-color: $marine-blue !important;
        text-decoration: underline !important;
    }
}

.btn-change {
    @include btn;
    &:hover {
        background-color: $pale-light-blue-200 !important;
        color: $marine-blue !important;
        // text-decoration: underline !important;
    }
}

.btn-download:disabled {
    background: #e0e0e0 !important;
    border-radius: 4px;
    color: gray !important;
}

.btn-process {
    background-color: $ocean-blue-two !important;
    color: $white !important;
    font-family: "MyriadPro", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    min-width: 120px !important;
    box-shadow: none !important;
}

.btn-uploaded {
    background-color: #e2eff4 !important;
    color: $ocean-blue-two !important;
    @include btn;
}

.btn-close {
    background-color: $pale-red-two !important;
    color: $white !important;
    font-family: "MyriadPro", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-left: 10px !important;
    box-shadow: none !important;
}

.btn-preview-download {
    color: $ocean-blue-two !important;
    font-family: "MyriadPro", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-left: 10px !important;
    box-shadow: none !important;
    border: 1px solid $ocean-blue-two !important;
    &:hover {
        background-color: transparent !important;
        text-decoration: underline !important;
    }
}
