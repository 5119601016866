@import "../../fonts";
@import "../../variables";

.history-wrapper {
    background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
}

.title-header {
    height: 100px;
    width: 100%;
    @extend .bg-header-gradient;
    margin-bottom: -60px;
}

.table-row-blur {
    opacity: 0.9;
    cursor: default;
}

.file-status {
    display: flex;
    align-items: center;
}

.document-notes {
    font-style: normal;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #004165 !important;
}

.file-link {
    &:hover {
        background-color: transparent !important;
    }
}

.fileurl-preview {
    cursor: pointer !important;
    text-decoration: underline !important;
    color: $ocean-blue-two !important;
}

.tooltip-container {
    .title {
        @extend .myriadpro-semi-bold;
        @extend .f14;
    }

    .subtitle {
        @extend .myriadpro-regular;
        @extend .f12;

        span {
            margin: 0px 2px 0px 2px !important;
            @extend .myriadpro-semi-bold;
        }
    }
}

.redaction-status {
    display: inline-block;
    padding: 4px 8px 4px 8px;
}

.MuiTableCell-root {
    border-bottom: 1px solid #eeeeee !important;
}

.redact-history-table {
    @extend .app-table;
    thead {
        th:nth-child(1) {
            width: 5% !important;
        }
        th:nth-child(2) {
            width: 35% !important;
        }
        th:nth-child(3) {
            width: 20% !important;
        }
        th:nth-child(4) {
            width: 15% !important;
        }
        th:nth-child(5) {
            width: 25% !important;
        }
    }

    tbody {
        td:nth-child(1) {
            width: 5% !important;
        }
        td:nth-child(2) {
            width: 35% !important;
            overflow: hidden !important;
            word-break: break-word;
            span {
                overflow: hidden !important;
                white-space: nowrap;
                text-overflow: ellipsis !important;
            }
        }
        td:nth-child(3) {
            width: 20% !important;
        }
        td:nth-child(4) {
            width: 15% !important;
        }
        td:nth-child(5) {
            width: 25% !important;
        }
    }
}

@mixin history_icon_delete {
    width: 30px;
    height: 30px;
    padding: 8px 7px 7px 8px;
    border-radius: 4px !important;
}

.history-icon-delete {
    @include history_icon_delete;
    border: 1px solid $ocean-blue !important;

    .delete-icon {
        color: $ocean-blue !important;
    }
}

.history-icon-delete-disabled {
    @include history_icon_delete;
    cursor: default !important;
    border: 1px solid $warm-grey !important;

    .delete-icon {
        color: $warm-grey !important;
    }
}
