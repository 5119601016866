@import "../../fonts";
@import "../../variables";

.canvas-conatiner {
    margin: 10px 10px 5px 10px;
}

.document-preview {
    display: flex;
    background-color: #e6e6e6;
    flex-direction: column;
    align-items: center;
    // overflow-y: auto;
    position: relative;
}

.page-number {
    margin-top: 5px;
    text-align: left;
    font-size: 12px;
}

.pdf-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #8080802b;
    margin: 10px 10px 5px 10px;
}

.toolbars {
    position: absolute;
    top: 0px;
    right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

    .page-section {
        .page-label {
            @include lato-regular;
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

.redact-document {
    height: calc(100vh - 65px) !important;
    // overflow: scroll; //check hidden
    display: flex;
    flex-direction: column;

    .scrollbar-container,
    .empty-list {
        background-color: #e6e6e6;
    }

    .redact-options-container {
        padding: 1rem 2rem !important;
        @include myriadpro-regular;
        height: 100%;
        background-color: $pale-light-blue-100 !important;
        border-left: 1px solid $pale-light-blue-200 !important;
        text-align: left !important;

        // overflow-y: scroll;
        .option-heading {
            @extend .f21;
            color: $prussian-blue;
            @include myriadpro-regular;
        }
        .option-description {
            margin-top: 10px;
            @extend .f12;
            // color: $prussian-blue;
            line-height: 17px;
            @include myriadpro-regular;
        }
        .option-label {
            @extend .f12;
            margin-bottom: 10px;
            @include myriadpro-regular;
        }
        .options-container {
            background-color: white;
            // padding: 10px;
            border-radius: 5px;
            width: 100%;
            .chip {
                margin-right: 10px;
                margin-bottom: 10px;
                .MuiChip-label {
                    font-size: 12px !important;
                    @include myriadpro-regular;
                }
            }
            .MuiTypography-root {
                @include myriadpro-regular;
                font-size: 13px !important;
            }
        }
        .keyword-container {
            background-color: white;
            padding: 5px;
            border-radius: 5px;
            min-height: 100px;
            display: block;
            flex-wrap: nowrap !important;
            .keyword {
                display: flex;
                flex-wrap: wrap !important;
            }
        }

        .btn-primary {
            width: 50%;
        }

        .MuiTextField-root {
            width: 100%;
            background-color: white;
            @include myriadpro-regular;
            .MuiInputBase-input {
                // padding: 0px !important;
                padding: 10px 10px !important;
                @include myriadpro-regular;
            }
        }

        .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
            width: 0;
            min-width: 30px;
            padding: 10px 10px !important;
        }

        .redaction-percent {
            background-color: white;
        }
    }

    .preview-header {
        display: flex;
        padding: 10px 20px;
        color: $marine-blue;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border-bottom: 1px solid $white-five;
        .pdf-goto-page {
            max-width: 64px;
            input {
                text-align: center;
                display: flex;
            }
        }
    }

    .preview-container {
        height: 100%;
        .redact-preview-section {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
            .preview-footer {
                z-index: 10;
                box-shadow: 0 8px 45px -12px rgba(27, 26, 26, 0.2);
                height: 80px;
                .btn-round {
                    margin-right: 10px;
                    img {
                        width: 20px;
                    }
                }
            }
        }
    }

    .redact-footer-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .edit-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.file-section {
    padding: 10px;
    .page-label {
        @include myriadpro-regular;
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
    }
}

.draw-box {
    @extend .f12;
    @include myriadpro-regular;
    color: $ocean-blue;
}

.info-box {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding: 15px;
    background: linear-gradient(
        0deg,
        rgba(235, 244, 249, 0.8) 0%,
        rgba(250, 253, 255, 1) 100%
    ) !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) !important;
    border: 1px solid #0072ae36 !important;
}

.rectangle-card {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $white-four;
    background-color: $white;
    @extend .f11;
    color: $prussian-blue;
    @include myriadpro-regular;
}

.default-keyword-wrapper {
    .input-wrapper {
        border: 1px solid #d9d9d9;
        background-color: #fff;
        border-radius: 4px;
        padding: 1px;
        display: flex;
        flex-wrap: wrap;

        &:hover {
            border-color: #e6e6e6;
        }

        &.focused {
            border-color: #40a9ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }

        & input {
            font-size: 12px !important;
            @extend .myriadpro-regular;
            height: auto;
            box-sizing: border-box;
            padding: 10px;
            width: 0;
            min-width: 30px;
            flex-grow: 1;
            border: 0;
            margin: 0;
            outline: 0;
        }
    }

    .listbox-container,
    ul {
        width: 280px;
        margin: 2px 0 0;
        padding: 0;
        position: absolute;
        list-style: none;
        background-color: #fff;
        overflow: auto;
        max-height: 250px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 1;

        & li {
            padding: 12px 20px;
            display: flex;

            & span {
                flex-grow: 1;
            }
        }

        & li[data-focus="true"] {
            margin: 4px;
            border-radius: 8px;
            color: $ocean-blue-two;
            cursor: pointer;
            background-color: $pale-light-blue-100 !important;
        }
    }
}

.custom-keyword-tag {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 4px !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 4px !important;
    background: linear-gradient(
        -180deg,
        rgb(255, 255, 255) 0%,
        rgb(247, 247, 247) 100%
    ) !important;
    filter: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin: 2px;

    span {
        font-size: 13px !important;
        color: $black;
        // margin-left: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: "MyriadPro", sans-serif !important;
        font-weight: 400 !important;
    }
    svg {
        font-size: 10px;
        color: $warm-grey;
        cursor: pointer;
        padding: 2px;
    }

    &:hover {
        border: 1px solid $dark-blue !important;
    }
    .MuiButton-text {
        padding: 4px 6px 4px 2px !important;
    }
    .MuiButton-label {
        display: flex;
        justify-content: start !important;
    }
}

.custom-keyword-button {
    font-family: "MyriadPro", sans-serif !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    min-width: auto !important;
}

.mx-2 {
    margin: 0px 4px 0px 4px !important;
}

.zoom-section {
    .zoom-btn {
        width: 60px !important;
        padding: 6px 10px;
    }

    .MuiSvgIcon-root {
        width: 1em !important;
    }
    .MuiButton-root {
        min-width: 40px !important;
    }
}
