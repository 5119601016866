@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");
@import "./colors";
@import "./fonts";
@import "./variables";
@import "./elements";
@import "./buttons";
@import "./input";
@import "./layout/index";
@import "./modules/authentication/index.scss";
@import "./modules/redact/index";
@import "./modules/welcome/index";
@import "./modules/report/index";
@import "./spaces";
@import "./modules/AppScrollbar.scss";
@import "./modules/access-management/index.scss";

:root {
    --launcher-width: 75px;
}

body {
    margin: 0px;
    background-color: #f7f7f7;
    overflow-x: hidden;
}

.Toastify__toast--success {
    background-color: $marine-blue !important;
}

.Toastify__toast--error {
    background-color: red !important;
}

//To disable eye icon on Edge browser
input::-ms-reveal,
input::-ms-clear {
    display: none !important;
}

.app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .app-header {
        position: sticky;
        top: 0;
        flex: 0;
        z-index: 9;
    }
    .app-body {
        flex: 1;
    }
}

.page-content {
    padding: 20px 40px;
}

.hamburger-page-header {
    background-color: white;
    z-index: 10;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid $primary-light;
    display: flex;
    align-items: center;
    @include lato-regular;
    font-size: 14px;
    .back-area {
        cursor: pointer;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        background-color: $primary-light;
        transition: width 1s ease-in;
        svg {
            color: $primary;
        }
        &:hover {
            svg {
                font-size: 20px;
            }
        }
        margin-right: 20px;
    }
    .menus {
        display: flex;
        align-items: center;
    }
}

.page-header {
    background-color: white;
    z-index: 10;
    width: 100%;
    padding: 0px 40px;
    border-bottom: 1px solid $primary-light;
    .header-menus {
        display: flex;
        .header-menu {
            @include poppins;
            padding: 30px;
            color: $secondary-grey;
            cursor: pointer;
            text-decoration: none;
        }
        .active-menu {
            @include poppins-semi-bold;
            color: $primary;
            border-bottom: 2px solid $primary;
        }
    }
}

.heading {
    @include poppins-semi-bold;
    font-size: 20px;
    color: $text-black;
}

.sub-heading {
    @include poppins-semi-bold;
    font-size: 20px;
    padding: 10px 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid $primary-light;
}

.app-inputbox {
    input {
        @include myriadpro-regular;
    }
}

.dark-link {
    color: $primary;
}

.outlined-corner-btn {
    border-radius: 50px !important;
    background-color: white !important;
    color: $primary !important;
    border: 1px solid $primary !important;
}

.app-table {
    .MuiTableCell-head {
        @include myriadpro-regular;
        font-size: 14px;
        padding-left: 10px;
        font-weight: 600 !important;
    }
    .MuiTableCell-body {
        @include myriadpro-regular;
        font-size: 14px;
        padding-left: 10px;
    }
    .primary-border-bottom {
        border-bottom: 2px solid $primary-light;
    }
}

.striped-table {
    .MuiTableCell-root {
        border: none !important;
    }
    tbody {
        tr:nth-of-type(odd) {
            background-color: $primary-light !important;
        }
    }
}

.card,
.add-card {
    display: flex;
    align-items: center;
    @include lato;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #cecece;
    cursor: pointer;
    margin-bottom: 20px;
    .radio {
        margin-right: 10px;
        width: 20px;
    }
    .card-type {
        margin-left: 10px;
        height: 30px;
    }
    .default {
        color: $primary !important;
        font-weight: 500;
        font-size: 14px;
    }
}
.card {
    &:hover {
        background-color: $primary-light;
    }
}
.active-card {
    background-color: $primary-light;
    border: 1px solid $primary;
}
.no-padding {
    padding: 0px !important;
}

.app-confirm-dialog {
    .title {
        @include poppins-bold;
    }
    .sub-title {
        @include lato-regular;
        font-size: 14px;
    }
}

.document-header {
    // margin-top: 10px;
    @include myriadpro-regular;
    color: $prussian-blue;
    @extend .f20;
}

.drop-zone {
    background: linear-gradient(
        -180deg,
        rgb(255, 255, 255) 0%,
        rgb(237, 244, 249) 100%
    ) !important;
    border: 1px solid $pale-light-blue-200;
    border-radius: 5px;
    .drop-hint-area,
    .drop-preview {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 400px;
    }
    .drop-hint-area {
        justify-content: center;
    }
    .drop-preview {
        justify-content: space-between;
        @include myriadpro-regular;
        font-size: 14px;
        .btn-primary {
            margin-top: 30px;
        }
        .preview-image {
            max-width: 100%;
            max-height: 100%;
        }
        .upload-section {
            margin-top: 30px;
            display: flex;
            width: 400px;
            img {
                width: 40px;
                margin-right: 20px;
            }
            .file-preview {
                flex: 1;
                .upload-progress {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
    .drop-hint-area {
        img {
            width: 150px;
        }
        .title {
            margin-top: 10px;
            @include myriadpro-regular;
            color: $black;
            @extend .f16;
        }
        .sub-title {
            margin-top: 10px;
            color: $ocean-blue;
            text-align: center;
            @include myriadpro-regular;
            font-size: 12px;
        }
        button {
            margin-top: 20px;
            background-color: $ocean-blue-two;
            @include myriadpro-regular;
            font-size: 12px;
            color: $white;
            text-align: center;
            width: 20%;
            &:hover {
                background-color: $marine-blue;
                text-decoration: underline;
            }
        }
    }
}

.justify-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.align-vertical-center {
    display: flex;
    align-items: center;
}

.cursor {
    cursor: pointer;
}

.empty-list {
    flex-direction: column;
    // height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 150px;
    }
    .title {
        margin: 20px 0px;
    }
}
.empty-list-height {
    height: 300px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

#delete-btn {
    background: white;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 9;
}

.start-content {
    display: flex;
    justify-content: flex-start;
}

.space-between-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.end-content {
    display: flex;
    justify-content: flex-end;
}

.align-center {
    display: flex;
    align-items: center;
}
.justify-center {
    display: flex;
    justify-content: center;
}

.MuiOutlinedInput-input {
    padding: 9px 10px !important;
}

.app-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .bg-header-gradient;
}

.text-center {
    text-align: center !important;
}

a {
    text-decoration: none !important;
}

.justify-end {
    display: flex;
    justify-content: end !important;
}

// .app-checkbox {
@mixin checkbox-container {
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px !important;
}

.checkbox-selected {
    @include checkbox-container;
    background-color: $marine-blue !important;
}

.checkbox-disabled {
    @include checkbox-container;
    background-color: $marine-blue !important;
}

.checkbox-unselected {
    @include checkbox-container;
    background-color: $white !important;
    border: 1px solid $grey-500 !important;
}
//}

.scrollbar-container {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.app-header-text {
    font-weight: 100 !important ;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important ;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.disable-button-hover {
    background-color: transparent !important;
}

.flex {
    display: flex;
}

// Tooltip
.tooltip-container {
    position: relative;
    button:hover {
        background-color: transparent !important;
    }
    .tooltip-content {
        width: 270px;
    }
}
.tooltip-content {
    position: absolute;
    right: 0;
    background-color: gray;
    font-size: 12px;
    padding: 10px;
    color: white;
    @include lato-regular;
    border-radius: 5px;
    z-index: 9;
}
.tooltip-content {
    display: none;
}
button:hover + .tooltip-content,
button:focus + .tooltip-content {
    display: block;
}

.overflowX {
    overflow-x: hidden !important;
    margin-bottom: 0px !important;
}
