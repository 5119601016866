$spaces: 50;
$scale: 8;
@mixin margin($size) {
    margin: $size * $scale + px !important;
}
@mixin margin-top($size) {
    margin-top: $size * $scale + px !important;
}
@mixin margin-left($size) {
    margin-left: $size * $scale + px !important;
}
@mixin margin-right($size) {
    margin-right: $size * $scale + px !important;
}
@mixin margin-bottom($size) {
    margin-bottom: $size * $scale + px !important;
}
@mixin padding($size) {
    padding: $size * $scale + px !important;
}
@mixin padding-top($size) {
    padding-top: $size * $scale + px !important;
}
@mixin padding-left($size) {
    padding-left: $size * $scale + px !important;
}
@mixin padding-right($size) {
    padding-right: $size * $scale + px !important;
}
@mixin padding-bottom($size) {
    padding-bottom: $size * $scale + px !important;
}
@for $i from 1 through $spaces {
    .m-#{$i} {
        @include margin($i);
    }
    .mt-#{$i} {
        @include margin-top($i);
    }
    .ml-#{$i} {
        @include margin-left($i);
    }
    .mr-#{$i} {
        @include margin-right($i);
    }
    .mb-#{$i} {
        @include margin-bottom($i);
    }
    .mx-#{$i} {
        @include margin-left($i);
        @include margin-right($i);
    }
    .my-#{$i} {
        @include margin-top($i);
        @include margin-bottom($i);
    }
    .p-#{$i} {
        @include padding($i);
    }
    .pt-#{$i} {
        @include padding-top($i);
    }
    .pl-#{$i} {
        @include padding-left($i);
    }
    .pr-#{$i} {
        @include padding-right($i);
    }
    .pb-#{$i} {
        @include padding-bottom($i);
    }
    .px-#{$i} {
        @include padding-left($i);
        @include padding-right($i);
    }
    .py-#{$i} {
        @include padding-top($i);
        @include padding-bottom($i);
    }
}
