.page-content1 {
    padding: 0px 0px;
    // background-color: #fcfcff;
}
.access-management-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // @include poppins-semi-bold;
    border-bottom: 1px solid #dcdde2 !important;
    .edit-heading {
        font-weight: 700;
        font-size: 16px;
        color: var(--text-primary);
    }
    .modal-close-icon {
        background-color: #f0f0f3;
        cursor: pointer;
    }
}
.edit-modal-container {
    .functionality-box {
        border: 1px solid #dcdde2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
        padding: 8px;

        .functionality-heading {
            font-weight: 600;
            font-size: 13px;
        }
    }
    .functionality-box-disable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
        padding: 8px;
        background-color: #eeeff2;

        .functionality-heading {
            font-size: 13px;
            color: #60668c;
        }
    }
}
.redaction-template-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-color {
    color: #191e38;
}
.btn-save-changes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.policy-update-btn {
    background-color: $marine-blue !important;
    color: white !important;
}
.group-name-style {
    .MuiInputBase-input {
        font-size: 13px !important;
        font-weight: 600 !important;
    }
}
