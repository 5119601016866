@import "../../fonts";

.upload-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.file-hint {
    margin-top: 10px;
    color: $ocean-blue !important;
    text-align: center;
    @include myriadpro-regular;
    font-size: 14px;
    text-decoration: underline !important;
    cursor: pointer;
}

.file-thumbnails {
    padding-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 250px;
    overflow-y: auto;
    .file-thumb {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px;
        align-items: center;
        width: 140px;
        height: 120px;
        border-radius: 8px;
        margin: 10px;
        background: $white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        border: 1px solid $white-five;
        .file-logo {
            max-width: 50px;
            max-height: 50px;
        }
        .icon-tick {
            width: 20px;
            height: 20px;
        }
        .file-name {
            font-size: 14px;
            height: 40px;
            overflow: hidden;
            word-break: break-word;
        }
        .icon-remove {
            position: absolute;
            top: -20px;
            right: -20px;
        }
        .progress {
            width: 100%;
            height: 15px !important;
            border-radius: 15px;
        }

        .MuiLinearProgress-colorPrimary {
            background-color: #cdd8e1 !important;
        }
        .MuiLinearProgress-barColorPrimary {
            background-color: white !important;
            border: 2px solid #cdd8e1;
            border-radius: 10px;
            width: calc(100% - 4px);
        }
    }
}

.upload-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;
    border-top: 1px solid #cae1ec;
    .btn-browse,
    .btn-green {
        background-color: $ocean-blue-two;
        @include myriadpro-regular;
        font-size: 14px;
        color: $white !important;
        text-align: center;
        width: 200px;
        text-transform: none !important;
    }
    .btn-browse {
        margin-right: 20px;
        &:hover {
            background-color: $marine-blue;
            text-decoration: underline;
        }
        &:disabled {
            opacity: 0.55 !important;
        }
    }
    .btn-green {
        background-color: #028a14;
        &:hover {
            text-decoration: underline;
            background-color: $primary-dark-green;
        }
        &:disabled {
            opacity: 0.55 !important;
        }
    }
}
