.login-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: scroll !important;
}
.p50 {
    padding: 50px;
}
